<template>
  <div>
    <v-card flat>
      <v-container fluid>
        <v-row>
          <v-card-text class="headline red white--text pl-10">Навигация</v-card-text>
          <v-col cols="3">
            <v-list rounded>
              <v-list-item color="red" class="title grey lighten-4 font-weight-light" to="/" @click="closeMenu">Главная страница</v-list-item>
              <!-- <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme1" @click="closeMenu">Законодательная база в области пожарной безопасности</v-list-item>
              <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme2" @click="closeMenu">Организационные мероприятия по обеспечению ПБ в зданиях и помещениях с массовым пребыванием людей</v-list-item>
              <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme3" @click="closeMenu">Меры ПБ в зданиях и помещениях с массовым скоплением людей</v-list-item>
              <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme4" @click="closeMenu">Первичные средства пожаротушения, автоматические установки пожарной сигнализации и пожаротушения</v-list-item>
              <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme5" @click="closeMenu">Действия сотрудников и руководителя при возникновении пожара, вызов пожарной охраны</v-list-item>
              <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme6" @click="closeMenu">Оказание первой помощи пострадавшим при пожаре</v-list-item> -->
            </v-list>
            <div v-if="nav === 'theme1'">
              <v-card class="d-flex justify-center align-center mt-16" flat>
                <v-img
                  lazy-src="img/material/balance.svg"
                  max-width="80%"
                  src="img/material/balance.svg"
                  class="pa-5"
                ></v-img>
              </v-card>
            </div>
            <div v-else-if="nav === 'theme2'">
              <v-card class="d-flex justify-center align-center mt-16" flat>
                <v-img
                  lazy-src="img/material/human.svg"
                  max-width="80%"
                  src="img/material/human.svg"
                  class="pa-5"
                ></v-img>
              </v-card>
            </div>
            <div v-else-if="nav === 'theme3'">
              <v-card class="d-flex justify-center align-center mt-16" flat>
                <v-img
                  lazy-src="img/material/hotel-2.svg"
                  max-width="80%"
                  src="img/material/hotel-2.svg"
                  class="pa-5"
                ></v-img>
              </v-card>
            </div>
            <div v-else-if="nav === 'theme4'">
              <v-card class="d-flex justify-center align-center mt-16" flat>
                <v-img
                  lazy-src="img/material/fire-extinguisher.svg"
                  max-width="80%"
                  src="img/material/fire-extinguisher.svg"
                  class="pa-5"
                ></v-img>
              </v-card>
            </div>
            <div v-else-if="nav === 'theme5'">
              <v-card class="d-flex justify-center align-center mt-16" flat>
                <v-img
                  lazy-src="img/material/fireman.svg"
                  max-width="80%"
                  src="img/material/fireman.svg"
                  class="pa-5"
                ></v-img>
              </v-card>
            </div>
            <div v-else-if="nav === 'theme6'">
              <v-card class="d-flex justify-center align-center mt-16" flat>
                <v-img
                  lazy-src="img/material/shield.svg"
                  max-width="80%"
                  src="img/material/shield.svg"
                  class="pa-5"
                ></v-img>
              </v-card>
            </div>
          </v-col>
          <v-col cols="9">
            <v-card flat>
              <div v-if="nav === 'allThemes'">
                <v-card-title class="headline">Список всех тем</v-card-title>
                <v-list>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme1" @click="closeMenu">Законодательная база в области пожарной безопасности</v-list-item>
                  <v-list-item disabled color="red" class="subtitle-1 font-weight-light" to="/theme2" @click="closeMenu">Организационные мероприятия по обеспечению ПБ в зданиях и помещениях с массовым пребыванием людей</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme3" @click="closeMenu">Меры ПБ в зданиях и помещениях с массовым скоплением людей</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme4" @click="closeMenu">Первичные средства пожаротушения, автоматические установки пожарной сигнализации и пожаротушения</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme5" @click="closeMenu">Действия сотрудников и руководителя при возникновении пожара, вызов пожарной охраны</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/theme6" @click="closeMenu">Оказание первой помощи пострадавшим при пожаре</v-list-item>
                </v-list>
              </div>
              <div v-else-if="nav === 'theme1'">
                <v-card-title class="headline">Законодательная база в области пожарной безопасности</v-card-title>
                <v-list>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page1" @click="closeMenu">Основные нормативные документы, регламентирующие требования пожарной безопасности</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page2" @click="closeMenu">Инструкция о мерах пожарной безопасности</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page3" @click="closeMenu">Система обеспечения пожарной безопасности</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page4" @click="closeMenu">Права и обязанности граждан и руководителей</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page5" @click="closeMenu">Ответственность за обеспечение ПБ</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page5-1" @click="closeMenu">Политика пао «Газпром» в области охраны труда, промышленной и пожарной безопасности, безопасности дорожного движения</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/quiz1" @click="closeMenu">Тестирование по теме</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/workshop1" @click="closeMenu">Практическое задание</v-list-item>
                </v-list>
              </div>
              <div v-else-if="nav === 'theme2'">
                <v-card-title class="headline">Организационные мероприятия по обеспечению ПБ в зданиях и помещениях с массовым пребыванием людей</v-card-title>
                <v-list>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page6" @click="closeMenu">Основные причины пожаров в городской местности</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page7" @click="closeMenu">Статистика пожаров в общественных зданиях</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page8" @click="closeMenu">Факторы, представляющие опасность для при пожарах</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page9" @click="closeMenu">Организационные мероприятия по установлению противопожарного режима</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page10" @click="closeMenu">Порядок обучения служащих мерам ПБ на рабочих местах</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page11" @click="closeMenu">Создание пожарно-технических комиссий и добровольных пожарных дружин</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/quiz2" @click="closeMenu">Тестирование по теме</v-list-item>
                </v-list>
              </div>
              <div v-else-if="nav === 'theme3'">
                <v-card-title class="headline">Меры ПБ в зданиях и помещениях с массовым скоплением людей</v-card-title>
                <v-card flat>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-list>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page12" @click="closeMenu">Меры ПБ при эксплуатации электрических сетей, электрооборудования и электронагревательных приборов</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page13" @click="closeMenu">Общие правила при эксплуатации электроустановок, электросетей, электрооборудования</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page14" @click="closeMenu">Требования ПБ при работе с электроприборами, офисным оборудованием и оргтехникой</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page15" @click="closeMenu">Запрещенные действия</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page16" @click="closeMenu">Аварийные режимы работы электроустановок</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page17" @click="closeMenu">Воспламеняющиеся жидкости</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page18" @click="closeMenu">Пожаровзрывоопасность</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page19" @click="closeMenu">Хранение, обращение, транспортирование ЛВЖ, ГЖ, ГГ</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page20" @click="closeMenu">Разработка плана эвакуации людей при пожаре, содержание путей эвакуации</v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="6">
                        <v-list>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page21" @click="closeMenu">Эвакуационные выходы</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page22" @click="closeMenu">Запрещенные дейстия при эксплуатации эвакуационныз путей, эвакуационных и аварийных выходов</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page23" @click="closeMenu">Пользование лифтами во время пожара</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page24" @click="closeMenu">Особенности распростронения огня в зданиях повышенной этажности</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page25" @click="closeMenu">Повышенная опасность продуктов горения</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page26" @click="closeMenu">Незадымляемые лестничные клетки</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page27" @click="closeMenu">Порядок хранения печатной продукции и документов</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/quiz3" @click="closeMenu">Тестирование по теме</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/taskworkshop3" @click="closeMenu">Практическое задание</v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </div>
              <div v-else-if="nav === 'theme4'">
                <v-card-title class="headline">Первичные средства пожаротушения, автоматические установки пожарной сигнализации и пожаротушения</v-card-title>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <v-list>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page28" @click="closeMenu">Первичные средства пожаротушения'</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page29" @click="closeMenu">Порошковый огнетушитель</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page30" @click="closeMenu">Углекислотный огнетушитель</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page31" @click="closeMenu">Аэрозольный огнетушитель</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page32" @click="closeMenu">Воздушно-пенный огнетушитель</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page33" @click="closeMenu">Автоматическая установка пожаротушения (АУП)</v-list-item>
                        </v-list>
                      </v-col>
                      <v-col cols="6">
                        <v-list>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page34" @click="closeMenu">Автоматическая установка пожарной сигнализации (АУПС)</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page35" @click="closeMenu">Нормы обеспечения учреждений средствами пожаротушения</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/quiz4" @click="closeMenu">Тестирование по теме</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/workshop2-1" @click="closeMenu">Практическое задание "Определить порядок действий при работе с огнетушителем"</v-list-item>
                          <v-list-item color="red" class="subtitle-1 font-weight-light" to="/taskworkshop4" @click="closeMenu">Практическое задание "Потоушить виртуальное возгорание"</v-list-item>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-container>
              </div>
              <div v-else-if="nav === 'theme5'">
                <v-card-title class="headline">Действия сотрудников и руководителя при возникновении пожара, вызов пожарной охраны</v-card-title>
                <v-list>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page36" @click="closeMenu">Действия сотрудников при возникновении пожара</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page37" @click="closeMenu">Запрещенные действия при тушении пожара</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page38" @click="closeMenu">Обязанности руководителя при возникновении пожара</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page39" @click="closeMenu">Вызов, встреча и сопровождение пожарных команд к месту пожара</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page40" @click="closeMenu">Порядок проведения эвакуации</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page41" @click="closeMenu">Правила проведения эвакуации</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page42" @click="closeMenu">Действия в случае значительного задымления</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page43" @click="closeMenu">Действия по предотвращению паники</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/quiz5" @click="closeMenu">Тестирование по теме</v-list-item>
                </v-list>
              </div>
              <div v-else-if="nav === 'theme6'">
                <v-card-title class="headline">Оказание первой помощи пострадавшим при пожаре</v-card-title>
                <v-list>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page44" @click="closeMenu">Первая помощь при ожогах</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page45" @click="closeMenu">Первая помощь при отравлении продуктами сгорания</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page46" @click="closeMenu">Термоингаляционное поражение дыхательных путей</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page47" @click="closeMenu">Первая помощь при травматическом шоке</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page48" @click="closeMenu">Первая помощь при ранениях</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page49" @click="closeMenu">Первая помощь при кровотичении</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/page50" @click="closeMenu">Первая помощь при переломах костей</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/quiz6" @click="closeMenu">Тестирование по теме</v-list-item>
                  <v-list-item color="red" class="subtitle-1 font-weight-light" to="/workshop6-1" @click="closeMenu">Практическое задание</v-list-item>
                </v-list>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    menu: {
      type: Boolean
    },
    nav: String
  },
  data: () => ({
    menuTabs: null
  }),
  computed: {
    ...mapGetters([
      'allMenu'
    ])
  },
  methods: {
    closeMenu: function () {
      this.$emit('closeMenu')
    }
  }
}
</script>
