import Vue from 'vue'
import Vuex from 'vuex'
import Card from './modules/card'
import Menu from './modules/menu'
import Setting from './modules/setting'
import Quiz1 from './modules/quiz1'
import Quiz2 from './modules/quiz2'
import Quiz3 from './modules/quiz3'
import Quiz4 from './modules/quiz4'
import Quiz5 from './modules/quiz5'
import Quiz6 from './modules/quiz6'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Card,
    Menu,
    Setting,
    Quiz1,
    Quiz2,
    Quiz3,
    Quiz4,
    Quiz5,
    Quiz6
  }
})
