export default {
  actions: {},
  mutations: {},
  state: {
    cards: [
      {
        id: 1,
        title: 'Постановление Правительства РФ от 16.09.2020г. №1479',
        subtitle: '"Об утверждении Правил противопожарного режима"',
        titledesc: 'Постановление Правительства РФ "Об утверждении Правил противопожарного режима":',
        desc: 'Устанавливает требования ПБ, определяющие порядок проведения людей, порядок организации производства и (или) содержания территорий, зданий, сооружений, помещений организации и других объектов защиты в целях обеспечения ПБ',
        view: false
      },
      {
        id: 2,
        title: 'Федеральный закон от 21.12.1994 №69-ФЗ',
        subtitle: '"О пожарной безопасности"',
        titledesc: 'ФЗ "О паожарной безопасности":',
        desc: '<p>Определяет общие правовые, экономические и социальные основы обеспечения ПБ в РФ;</p>',
        view: false
      }
    ]
  },
  getters: {
    allCards (state) {
      return state.cards
    }
  }
}
