<template>
  <v-app>
    <!-- <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      width="40%"
      class="pb-16"

    >
    <v-card-title>
      Навигация
    </v-card-title>

    <v-list>
       <v-list-item class="red" tag="a" to="/">
         <v-icon color="white" class="mr-7">mdi-home</v-icon>
         <span class="white--text">Главная страница</span>
       </v-list-item>
    </v-list>

    <v-list class="mb-16 pb-16">
      <v-list-group
        v-for="(item, i) in allMenu"
        :key="i"
        :prepend-icon="item.action"
        no-action
        color="red"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-html="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="child in item.items"
          :key="child.title"
          tag="a"
          :to="child.to"
        >
          <v-list-item-content >
            <v-list-item-title  v-html="child.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>

      </v-navigation-drawer> -->
    <v-dialog
      v-model="menu"
      width="80%"
    >
      <!-- <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="red lighten-2"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Click Me
        </v-btn>
      </template> -->

      <v-card>
        <Menu
        :menu="menu"
        @closeMenu="closeMenu"
        :nav="nav"
        ></Menu>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="menu = false"
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-app-bar
    app
    color="red"
    dark
    >
      <div v-if="menuPage">
        <v-app-bar-nav-icon @click="openMenu"></v-app-bar-nav-icon>
      </div>

      <v-toolbar-title>{{ page }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <!-- <v-tooltip bottom >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          >
            <v-icon>mdi-help-circle</v-icon>
          </v-btn>
        </template>
        <span>Справка</span>
      </v-tooltip> -->
    <div v-if="infoPage">

      <v-dialog
        transition="dialog-top-transition"
        max-width="80%"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            elevation="0"
            v-on="on"
            icon
          >
            <v-icon>mdi-format-letter-case</v-icon>
          </v-btn>
        </template>
        <template v-slot:default="dialog">
          <v-card flat>
            <v-card-title dark>
              Глоссарий
            </v-card-title>
            <v-card-text>
              <Glossary></Glossary>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                class="red"
                dark
                outlined
                @click="dialog.value = false"
              >Закрыть
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>

      <v-dialog
        v-model="docs"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            elevation="0"
            v-on="on"
            icon
          >
            <v-icon>mdi-file-document</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            dark
            color="red"
          >
            <v-toolbar-title>Дополнительные материалы для скачивания</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="docs = false"
              >
                Закрыть
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <Docs></Docs>
        </v-card>
      </v-dialog>
      <!-- <v-dialog
        v-model="membs"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            elevation="0"
            v-on="on"
            icon
          >
            <v-icon>mdi-bookmark-multiple</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-toolbar
            dark
            color="red"
          >
            <v-toolbar-title>Памятки по материалам курса</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="membs = false"
              >
                Закрыть
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <Membs></Membs>
        </v-card>
      </v-dialog> -->
    </div>
    </v-app-bar>

    <!-- content -->
    <!-- <div height="100vh" v-if="!auth"> -->
      <v-main class="mb-12 noselect">
        <router-view/>
      </v-main>
    <!-- </div> -->
    <!-- <div v-if="!auth">
      <v-card class="d-flex">
        ss
      </v-card>
    </div> -->
    <v-footer padless >
      <v-col
        class="text-center"
        cols="12"
      >
        © Научно-образовательный центр {{ new Date().getFullYear() }}г.
      </v-col>
      <!-- ptm button block -->
      <div v-if="arrowPage">
        <div class="ptm-arrow-block">
          <v-btn :to="prevPage" x-large class="mr-4">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-btn :to="nextPage" @click="setProgressPage" x-large>
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div>
      </div>
      <!-- /ptm button block -->
    </v-footer>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import Docs from '@/components/Docs.vue'
import Menu from '@/components/Menu.vue'
// import Membs from '@/components/Membs.vue'
import Glossary from '@/components/Glossary.vue'
export default {
  data: () => ({
    auth: false,
    currToken: 'ptm21',
    nav: '',
    selected: [],
    menu: false,
    selectedItem: 1,
    docs: false,
    membs: false,
    glossary: false,
    pageName: '',
    items: [
      {
        action: 'mdi-ticket',
        items: [{ title: 'List Item' }],
        title: 'Attractions'
      },
      {
        action: 'mdi-silverware-fork-knife',
        active: true,
        items: [
          { title: 'Breakfast & brunch' },
          { title: 'New American' },
          { title: 'Sushi' }
        ],
        title: 'Dining'
      },
      {
        action: 'mdi-school',
        items: [{ title: 'List Item' }],
        title: 'Education'
      },
      {
        action: 'mdi-run',
        items: [{ title: 'List Item' }],
        title: 'Family'
      },
      {
        action: 'mdi-bottle-tonic-plus',
        items: [{ title: 'List Item' }],
        title: 'Health'
      },
      {
        action: 'mdi-content-cut',
        items: [{ title: 'List Item' }],
        title: 'Office'
      },
      {
        action: 'mdi-tag',
        items: [{ title: 'List Item' }],
        title: 'Promotions'
      }
    ]
  }),
  computed: {
    ...mapGetters([
      'allMenu',
      'allSettings'
    ]),
    page () {
      return this.$route.meta.title
    },
    prevPage () {
      return this.$route.meta.prev
    },
    nextPage () {
      return this.$route.meta.next
    },
    progressPage () {
      return this.$route.meta.progress
    },
    arrowPage () {
      return this.$route.meta.arrows
    },
    menuPage () {
      return this.$route.meta.menu
    },
    infoPage () {
      return this.$route.meta.info
    }
  },
  methods: {
    log () {
      // console.log(this.allMenu,)
    },
    setProgressPage: function () {
      this.pageName = this.$route.meta.page
      // localStorage.setItem(this.pageName, this.$route.meta.progress)
      // this.pageProgressTheme1.push(localStorage.getItem(this.pageName, this.$route.meta.progress))
    },
    closeMenu: function () {
      this.menu = false
    },
    openMenu: function () {
      this.menu = true
      this.nav = this.$route.meta.nav
    }
  },
  components: {
    Docs,
    // Membs,
    Glossary,
    Menu
  },
  mounted () {
    if (this.currToken !== localStorage.authToken) {
      localStorage.removeItem('auth')
      this.$router.push({ name: 'Dashboard' })
    }
  }
}
</script>
<style lang="less">
* {
  word-break: break-word!important;
}
.ptm-arrow-block {
  position: fixed;
  top: 85%;
  right: 1%;
}
.ptm-footer {
  position:absolute;
  bottom:0;
}
.ptm-all-doc {
  display: flex;
  text-decoration: none;
  color: black;
}
.ptm-all-doc:hover {
  color: white;
  background-color: #FFEBEE;
}
.ptm-menu {
  text-decoration: none;
  color: unset!important;
}
@media (min-width: 1904px) {
.container {
    max-width: 1140px!important;
  }
}
.container--fluid {
    max-width: 100%!important;
  }
.noselect {
    -moz-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    -khtml-user-select: none;
}
</style>
