export default {
  actions: {},
  mutations: {},
  state: {
    questions: [
      {
        id: 1,
        text: 'Максимальное время наложения жгута составляет:',
        type: 'radio',
        answer: [
          { text: 'летом – 30 минут, зимой – 1 час' },
          { text: 'летом – 1 час, зимой – 30 минут', correct: true },
          { text: 'летом – 1 час, зимой – 2 часа' },
          { text: 'летом - 2 часа, зимой – 1 час' }

        ]
      },
      {
        id: 2,
        text: 'У пострадавшего резкая общая слабость, жажда, обморок при попытке встать, бледная, влажная и холодная кожа. О чем говорят эти симптомы?',
        type: 'radio',
        answer: [
          { text: 'перелом' },
          { text: 'травматический шок' },
          { text: 'острая кровопотеря', correct: true },
          { text: 'ранение' }
        ]
      },
      {
        id: 3,
        text: 'У пострадавшего неестественное положение руки, отек и резкая боль при попытке движения. О чем говорят эти симптомы?',
        type: 'radio',
        answer: [
          { text: 'внутреннее кровотечение' },
          { text: 'перелом', correct: true },
          { text: 'ранение' },
          { text: 'вывих' }
        ]
      },
      {
        id: 4,
        type: 'radio',
        text: 'Пострадавший чувствует слабость, головокружение, затруднение дыхания, тошноту. Его кожа бледно-синюшная. О чем говорят эти симптомы?',
        answer: [
          { text: 'внутреннее кровотечение' },
          { text: 'поражение нижних дыхательных путей' },
          { text: 'травматический шок' },
          { text: 'отравление продуктами сгорания', correct: true }
        ]
      },
      {
        id: 5,
        type: 'radio',
        text: 'У пострадавшего ожоги лица и шеи, боли в горле и нарушение глотания, на языке следы копоти. О чем говорят эти симптомы?',
        answer: [
          { text: 'травматический шок' },
          { text: 'поражение нижних дыхательных путей', correct: true },
          { text: 'отравление продуктами сгорания' },
          { text: 'внутреннее кровотечение' }
        ]
      }
    ]
  },
  getters: {
    allQuestionsQuiz6 (state) {
      return state.questions
    }
  }
}
