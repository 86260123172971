export default {
  actions: {},
  mutations: {},
  state: {
    settings:
    {
      token: 'ptm22',
      title: 'Главная страница',
      url: '/',
      prev: '/',
      next: '/ntd',
      icon: 'mdi-home'
    }
    // themes: [
    //   { theme1:
    //     [
    //       {
    //         id: 1,
    //         theme: 'Нормативные документы, регламентирующие требования ПБ',
    //         color: 'red'
    //       },
    //       {
    //         id: 2,
    //         theme: 'Инструкция о мерах пожарной безопасности',
    //         color: 'red'
    //       },
    //       {
    //         id: 3,
    //         theme: 'Система обеспечения пожарной безопасности',
    //         color: 'red'
    //       },
    //       {
    //         id: 4,
    //         theme: 'Права и обязанности граждан и руководителей',
    //         color: 'red'
    //       },
    //       {
    //         id: 5,
    //         theme: 'Ответственность за обеспечение ПБ',
    //         color: 'red'
    //       }
    //     ],
    //   },
    //   { theme2: [
    //     {
    //       id: 1,
    //       theme: 'Нормативные документы, регламентирующие требования ПБ',
    //       color: 'red'
    //     },
    //     {
    //       id: 2,
    //       theme: 'Инструкция о мерах пожарной безопасности',
    //       color: 'red'
    //     },
    //     {
    //       id: 3,
    //       theme: 'Система обеспечения пожарной безопасности',
    //       color: 'red'
    //     },
    //     {
    //       id: 4,
    //       theme: 'Права и обязанности граждан и руководителей',
    //       color: 'red'
    //     },
    //     {
    //       id: 5,
    //       theme: 'Ответственность за обеспечение ПБ',
    //       color: 'red'
    //     }
    //   ]
    //   }
    // ]
  },
  getters: {
    allSettings (state) {
      return state.settings
    }
  }
}
