<template>
  <v-row>
    <v-col cols="12">
      <v-tabs
      v-model="glossary"
      background-color="grey lighten-4"
      active-class="ptm-glossary"
      color="red"
      grow
      >
        <v-tab>
          А
        </v-tab>
        <v-tab>
          В
        </v-tab>
        <v-tab>
          Г
        </v-tab>
        <v-tab>
          Д
        </v-tab>
        <v-tab>
          К
        </v-tab>
        <v-tab>
          Л
        </v-tab>
        <v-tab>
          Н
        </v-tab>
        <v-tab>
          О
        </v-tab>
        <v-tab>
          П
        </v-tab>
        <v-tab>
          Р
        </v-tab>
        <v-tab>
          С
        </v-tab>
        <v-tab>
          Т
        </v-tab>
        <v-tab>
          Э
        </v-tab>
      </v-tabs>
      <v-tabs-items
      v-model="glossary"
      >
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Аварийный режим работы электроустановки</span> - режим, который сопровождается отклонением рабочих параметров от предельно допустимых значений. Характеризуется повреждением элементов системы электроснабжения, выходом из строя электрооборудования, перерывом электроснабжения.
            </v-card-text>
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Автоматическая установка пожаротушения (АУП)</span> - установка пожаротушения, автоматическая срабатывающая при привышении контролируемых фактором пожара установленных пороговых значений в защищаемой зоне.
            </v-card-text>
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Автоматическая установка пожарной сигнализации (АУПС)</span> - совокупность технических средств, предназначенных для обнаружения пожара, обработки и передачи извещения о пожаре, выдачи команд на включение и автоматических установок пожаротушения и включение установок систем противодымной защиты, технологического и инженерного оборудования, а также других устройств противопожарной защиты.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Вспышка</span> - быстрое сгорание газопаровоздушной смеси над поверхностью горючего вещества, сопровождающееся кратковременнным видимым свечением.
            </v-card-text>
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Воспламенение</span> - пламенное горение вещества, инициированное источником зажигания и продолжающееся после его удаления.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Государственный пожарный надзор </span> - бспециальный вид государственной надзорной деятельности, осуществляемый должностными лицами органов управления и подразделений Государственной противопожарной безопасности. Призван поддерживать высокий уровень ПБ в стране путем проведения обследований и проверок противопожарного состояния населенных пунктов, предприятий и организаций.
            </v-card-text>
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Горючие жидкости (ГЖ) </span> - жидкости, имеющие 60 ºС &lt; tвсп &lt; 120 ºС. Например: мазут, дизельное топливо, масла, лаки и др. ГЖ являются взрывоопасными, если нагреты до температуры вспышки.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Добровольная пожарная дружина (ДПД) </span> - территориальное или объектовое подразделение добровольной пожарной охраны, принимающее участие в профилактике пожаров и (или) участие в тушении пожаров и проведении аварийно-спасательных работ, оснащенное первичными средствами пожаротушения, пожарными мотопомпами и не имеющее на вооружении пожарных автомобилей и приспособленных для тушения пожаров технических средств.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Короткое замыкание (КЗ) </span> - электрическое соединение двух точек электрической цепи с различными значениями потенциала, не предусмотренное конструкцией устройства и нарушающее его нормальную работу.
            </v-card-text>
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Кровотечение </span> - это истечение крови из кровеносных сосудов в органы, ткани, полости организма или наружу.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Легковоспламеняющаяся жидкость (ЛВЖ)  </span> - жидкость, способная самостоятельно гореть после удаления источника зажигания и имеющая температуру вспышки от 18 до 61 ºС.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Незадымляемая лестничная клетка </span> - специальная конструкция, которая реализована в виде марша определенных геометрических размеров. Устанавливается в многоэтажных объектах в обязательном порядке.<br>Должна предусматривать транспортирование эвакуированных с помощью носилок.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Нижний (верхний) концентрационный предел распространения пламени</span> - минимальное (максимальное) содержание горючего вещества в однородной смеси с окислительной средой, при котором возможно распространение пламени по смеси на любое расстояние от источника зажигания.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Объект защиты </span> - продукция, в том числе имущество граждан или юридических лиц, государственное или муниципальное имущество (включая объекты, расположенные на территориях поселений, а также здания, сооружения, транспортные средства, технологические установки, оборудование, агрегаты, изделия и иное имущество), к которой установлены или должны быть установлены требования пожарной безопасности для предотвращения пожара и защиты людей при пожаре.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Огнетушители </span> - устройства для тушения пожара за счет выпуска запасенного огнетушащего вещества.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Ожог </span> - повреждения тканей организма, вызванное действием высокой температуры или химических веществ (щелочей, кислот, солей тяжелых металлов и др.)
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Паника (безотчетный страх) </span> - психологическое состояние, вызванное воздействием внешних условий, которые угрожают жизни. Выражается в чувстве острого страха, охватывающего человека или множество людей, которые неконтролируемо стремятся избежать опасной ситуации.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Первая помощь  </span> - комплекс мероприятий, направленных на восстановление или сохранение жизни и здоровья пострадавшего. Ее оказывает тот, кто находится рядом с пострадавшим (взаимопомощь), или сам пострадавший (самопомощь) до прибытия медицинского работника.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Первичные средства пожаротушения </span> - это устройства, инструменты и материалы, предназначенные для использования работниками организаций, личным составом подразделений пожарной охраны в целях тушения пожаров.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Перелом </span> - нарушение целостности кости, в результате травмы (падения, удара).
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Переходное сопротивление  </span> - сопротивление, возникающее в местах перехода тока с одного провода на другой или с провода на электроаппарат при наличии плохого контакта в местах соединений оконцеваний.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">План эвакуации </span> - документ, в котором указаны эвакуационные пути и выходы, установленные правила поведения, порядок и последовательность действий обслуживающего персонала на объекте при возникновении пожара.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Ранение (рана) </span> - нарушение целостности кожи, слизистой оболочки, органов в результате внешнего механического воздействия.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Самовоспламенение </span> - резкое увеличение скорости экзотермических объемных реакций, сопровождающееся пламенным горением и/или взрывом.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Система обеспечения пожарной безопасности </span> - совокупность сил, средств и мер правового, организационного, экономического, социального и научно- технического характера, направленных на профилактику и тушение пожаров, проведение аварийно-спасательных работ.
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Температура вспышки </span> - минимальная температура, при которой пары над нагретой поверхностью вспыхивают при поднесении к ним источника зажигания. Устойчивое горение не наблюдается.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Температура воспламенения </span> - наименьшая температура, при которой вещество выделяет горючие пары и газы с такой скоростью, что при воздействии источника зажигания возникает воспламенение.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Температура самовоспламенения </span> - наименьшая температура окружающей среды, при которой возникает самовоспламенение вещества.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Температурные пределы распространения пламени </span> - температуры, при которых насыщенный пар вещества образует в окислительной среде концентрации, равные соответственно нижнему и верхнему концентрационным пределам распространения пламени.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Травматический шок </span> - тяжелое, угрожающее жизни патологическое состояние, возникающее при тяжелых травмах (переломы костей таза, большая потеря крови, черепно-мозговая травма, повреждение внутренних органов).
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Эвакуационный выход </span> - выход на путь эвакуации, ведущий в безопасную зону и отвечающий требованиям безопасности.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Эвакуация людей при пожаре </span> - вынужденный процесс организованного самостоятельного движения людей непосредственно наружу или в безопасную зону из помещений, в которых возможно воздействие не людей опасных факторов пожара.
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Электрическая дуга </span> - электрический разряд в газовой среде между контактами, возникающий при размыкании электрического контакта или при нестабильности переходного сопротивления контактов (искрение).
            </v-card-text>
          </v-card>
          <v-card flat class="ma-2 mt-5">
            <v-card-text class="title font-weight-light">
              <span class="font-weight-bold">Электрическая перегрузка </span> - режим работы, когда по проводам и кабелям идет рабочий ток больше допустимого.
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    glossary: null
  })
}
</script>
