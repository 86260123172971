import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { Bar } from 'vue-chartjs'
import fancyBox from 'vue-fancybox'
import draggable from 'vuedraggable'
import VueFriendlyIframe from 'vue-friendly-iframe'
// import ModelGltf from 'vue-3d-model'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  Bar,
  fancyBox,
  draggable,
  VueFriendlyIframe,
  // ModelGltf,
  render: h => h(App)
}).$mount('#app')
