import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/auth',
    name: 'Auth',
    meta: { page: 'auth', title: 'Авторизация', menu: false, info: false, auth: false },
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue')
  },
  // {
  //   path: '/login',
  //   name: 'Auth',
  //   meta: { page: 'auth', title: 'Авторизация', menu: false, info: false, auth: false },
  //   component: () => import(/* webpackChunkName: "auth" */ '../views/Auth.vue')
  // },
  {
    path: '/',
    name: 'Dashboard',
    meta: { page: 'dashboard', title: 'Главная страница', menu: false, info: true, auth: true, nav: 'allThemes' },
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/page1',
    name: 'Page1',
    meta: { page: 'page1', title: 'Нормативные документы, регламентирующие требования ПБ', prev: '/theme1', next: '/page2', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme1' },
    component: () => import(/* webpackChunkName: "page1" */ '../views/Page1.vue')
  },
  {
    path: '/page2',
    name: 'Page2',
    meta: { page: 'page2', title: 'Инструкция о мерах пожарной безопасноти', prev: '/page1', next: '/page3', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme1' },
    component: () => import(/* webpackChunkName: "page2" */ '../views/Page2.vue')
  },
  {
    path: '/page3',
    name: 'Page3',
    meta: { page: 'page3', title: 'Система обеспечения пожарной безопасности', prev: '/page2', next: '/page4', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme1' },
    component: () => import(/* webpackChunkName: "page3" */ '../views/Page3.vue')
  },
  {
    path: '/page4',
    name: 'Page4',
    meta: { page: 'page4', title: 'Права и обязанности граждан и руководителей', prev: '/page3', next: '/page5', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme1' },
    component: () => import(/* webpackChunkName: "page4" */ '../views/Page4.vue')
  },
  {
    path: '/page5',
    name: 'Page5',
    meta: { page: 'page5', title: 'Ответственность за обеспечение ПБ', prev: '/page4', next: '/page5-1', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme1' },
    component: () => import(/* webpackChunkName: "page5" */ '../views/Page5.vue')
  },
  {
    path: '/page5-1',
    name: 'Page5-1',
    meta: { page: 'page5', title: 'Политика пао «Газпром» в области охраны труда, промышленной и пожарной безопасности, безопасности дорожного движения', prev: '/page5', next: '/quiz1', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme1' },
    component: () => import(/* webpackChunkName: "page5" */ '../views/Page5-1.vue')
  },
  {
    path: '/page6',
    name: 'Page6',
    meta: { page: 'page6', title: 'Основные причины пожаров в городской местности', prev: '/theme2', next: '/page7', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme2' },
    component: () => import(/* webpackChunkName: "page6" */ '../views/Page6.vue')
  },
  {
    path: '/page7',
    name: 'Page7',
    meta: { page: 'page7', title: 'Статистика пожаров в общественных зданиях', prev: '/page6', next: '/page8', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme2' },
    component: () => import(/* webpackChunkName: "page7" */ '../views/Page7.vue')
  },
  {
    path: '/page8',
    name: 'Page8',
    meta: { page: 'page8', title: 'Факторы, представляющие опасность для людей при пожаре', prev: '/page7', next: '/page9', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme2' },
    component: () => import(/* webpackChunkName: "page8" */ '../views/Page8.vue')
  },
  {
    path: '/page9',
    name: 'Page9',
    meta: { page: 'page9', title: 'Организационные мероприятия по установлению противопожарного режима', prev: '/page8', next: '/page10', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme2' },
    component: () => import(/* webpackChunkName: "page9" */ '../views/Page9.vue')
  },
  {
    path: '/page10',
    name: 'Page10',
    meta: { page: 'page10', title: 'Порядок обучения служащих мерам ПБ на рабочих местах', prev: '/page9', next: '/page11', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme2' },
    component: () => import(/* webpackChunkName: "page10" */ '../views/Page10.vue')
  },
  {
    path: '/page11',
    name: 'Page11',
    meta: { page: 'page11', title: 'Создание пожарно-технических комиссий и добровольных пожарных дружин', prev: '/page10', next: '/quiz2', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme2' },
    component: () => import(/* webpackChunkName: "page11" */ '../views/Page11.vue')
  },
  {
    path: '/page12',
    name: 'Page12',
    meta: { page: 'page12', title: 'Меры ПБ при эксплуатации электрических сетей, электрооборудования и электронагревательных приборов', prev: '/theme3', next: '/page13', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page12" */ '../views/Page12.vue')
  },
  {
    path: '/page13',
    name: 'Page13',
    meta: { page: 'page13', title: 'Общие правила при эксплуатации электроустановок, электросетей, электрооборудования', prev: '/page12', next: '/page14', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page13" */ '../views/Page13.vue')
  },
  {
    path: '/page14',
    name: 'Page14',
    meta: { page: 'page14', title: 'Требования ПБ при работе с электроприборами, офисным оборудованием и оргтехникой', prev: '/page13', next: '/page15', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page14" */ '../views/Page14.vue')
  },
  {
    path: '/page15',
    name: 'Page15',
    meta: { page: 'page15', title: 'Запрещенные действия', prev: '/page14', next: '/page16', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page15" */ '../views/Page15.vue')
  },
  {
    path: '/page16',
    name: 'Page16',
    meta: { page: 'page16', title: 'Аварийные режимы работы электроустановок', prev: '/page15', next: '/page17', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page16" */ '../views/Page16.vue')
  },
  {
    path: '/page17',
    name: 'Page17',
    meta: { page: 'page17', title: 'Воспламеняющиеся жидкости', prev: '/page16', next: '/page18', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page17" */ '../views/Page17.vue')
  },
  {
    path: '/page18',
    name: 'Page18',
    meta: { page: 'page18', title: 'Пожаровзрывоопасность', prev: '/page17', next: '/page19', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page18" */ '../views/Page18.vue')
  },
  {
    path: '/page19',
    name: 'Page19',
    meta: { page: 'page19', title: 'Хранение, обращение, транспортирование ЛВЖ, ГЖ, ГГ', prev: '/page18', next: '/page20', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page19" */ '../views/Page19.vue')
  },
  {
    path: '/page20',
    name: 'Page20',
    meta: { page: 'page20', title: 'Разработка плана эвакуации людей при пожаре, содержание путей эвакуации', prev: '/page19', next: '/page21', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page20" */ '../views/Page20.vue')
  },
  {
    path: '/page21',
    name: 'Page21',
    meta: { page: 'page21', title: 'Эвакуационные выходы', prev: '/page20', next: '/page22', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page21" */ '../views/Page21.vue')
  },
  {
    path: '/page22',
    name: 'Page22',
    meta: { page: 'page22', title: 'Запрещенные действия при эксплуатации эвакуационных путей, эвакуационных и аварийных выходов', prev: '/page21', next: '/page23', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page22" */ '../views/Page22.vue')
  },
  {
    path: '/page23',
    name: 'Page23',
    meta: { page: 'page23', title: 'Пользование лифтами во время пожара', prev: '/page22', next: '/page24', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page23" */ '../views/Page23.vue')
  },
  {
    path: '/page24',
    name: 'Page24',
    meta: { page: 'page24', title: 'Особенности распространения огня в зданиях повышенной этажности', prev: '/page23', next: '/page25', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page24" */ '../views/Page24.vue')
  },
  {
    path: '/page25',
    name: 'Page25',
    meta: { page: 'page25', title: 'Повышенная опасность продуктов горения', prev: '/page24', next: '/page26', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page25" */ '../views/Page25.vue')
  },
  {
    path: '/page26',
    name: 'Page26',
    meta: { page: 'page26', title: 'Незадымляемые лестничные клетки', prev: '/page25', next: '/page27', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page26" */ '../views/Page26.vue')
  },
  {
    path: '/page27',
    name: 'Page27',
    meta: { page: 'page27', title: 'Порядок хранения печатной продукции и документов', prev: '/page26', next: '/quiz3', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "page27" */ '../views/Page27.vue')
  },
  {
    path: '/page28',
    name: 'Page28',
    meta: { page: 'page28', title: 'Первичные средства пожаротушения', prev: '/theme4', next: '/page29', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme4' },
    component: () => import(/* webpackChunkName: "page28" */ '../views/Page28.vue')
  },
  {
    path: '/page29',
    name: 'Page29',
    meta: { page: 'page29', title: 'Порошковый огнетушитель (ОП)', prev: '/page28', next: '/page30', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme4' },
    component: () => import(/* webpackChunkName: "page29" */ '../views/Page29.vue')
  },
  {
    path: '/page30',
    name: 'Page30',
    meta: { page: 'page30', title: 'Углекислотный огнетушитель (ОУ)', prev: '/page29', next: '/page31', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme4' },
    component: () => import(/* webpackChunkName: "page30" */ '../views/Page30.vue')
  },
  {
    path: '/page31',
    name: 'Page31',
    meta: { page: 'page31', title: 'Воздушно-пенный огнетушитель (ОВП)', prev: '/page30', next: '/page32', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme4' },
    component: () => import(/* webpackChunkName: "page31" */ '../views/Page31.vue')
  },
  {
    path: '/page32',
    name: 'Page32',
    meta: { page: 'page32', title: 'Аэрозольные огнетушители (ГОА и АГС)', prev: '/page31', next: '/page33', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme4' },
    component: () => import(/* webpackChunkName: "page32" */ '../views/Page32.vue')
  },
  {
    path: '/page33',
    name: 'Page33',
    meta: { page: 'page33', title: 'Автоматическая установка пожаротушения', prev: '/page32', next: '/page34', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme4' },
    component: () => import(/* webpackChunkName: "page33" */ '../views/Page33.vue')
  },
  {
    path: '/page34',
    name: 'Page34',
    meta: { page: 'page34', title: 'Автоматическая установка пожарной сигнализации (АУПС)', prev: '/page33', next: '/page35', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme4' },
    component: () => import(/* webpackChunkName: "page34" */ '../views/Page34.vue')
  },
  {
    path: '/page35',
    name: 'Page35',
    meta: { page: 'page35', title: 'Нормы обеспечения учреждений средствами пожаротушения', prev: '/page34', next: '/quiz4', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme4' },
    component: () => import(/* webpackChunkName: "page35" */ '../views/Page35.vue')
  },
  {
    path: '/page36',
    name: 'Page36',
    meta: { page: 'page36', title: 'Действия сотрудников при возникновении пожара', prev: '/theme5', next: '/page37', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme5' },
    component: () => import(/* webpackChunkName: "page36" */ '../views/Page36.vue')
  },
  {
    path: '/page37',
    name: 'Page37',
    meta: { page: 'page37', title: 'Запрещенные действия при тушении пожара', prev: '/page36', next: '/page38', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme5' },
    component: () => import(/* webpackChunkName: "page37" */ '../views/Page37.vue')
  },
  {
    path: '/page38',
    name: 'Page38',
    meta: { page: 'page38', title: 'Обязанности руководителя при возникновении пожара', prev: '/page37', next: '/page39', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme5' },
    component: () => import(/* webpackChunkName: "page38" */ '../views/Page38.vue')
  },
  {
    path: '/page39',
    name: 'Page39',
    meta: { page: 'page39', title: 'Вызов, встреча и сопровождение пожарных команд к месту пожара', prev: '/page38', next: '/page40', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme5' },
    component: () => import(/* webpackChunkName: "page39" */ '../views/Page39.vue')
  },
  {
    path: '/page40',
    name: 'Page40',
    meta: { page: 'page40', title: 'Порядок проведения эвакуации', prev: '/page39', next: '/page41', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme5' },
    component: () => import(/* webpackChunkName: "page40" */ '../views/Page40.vue')
  },
  {
    path: '/page41',
    name: 'Page41',
    meta: { page: 'page41', title: 'Правила проведения эвакуации', prev: '/page40', next: '/page42', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme5' },
    component: () => import(/* webpackChunkName: "page41" */ '../views/Page41.vue')
  },
  {
    path: '/page42',
    name: 'Page42',
    meta: { page: 'page42', title: 'Действия в случае значительного задымления', prev: '/page41', next: '/page43', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme5' },
    component: () => import(/* webpackChunkName: "page42" */ '../views/Page42.vue')
  },
  {
    path: '/page43',
    name: 'Page43',
    meta: { page: 'page43', title: 'Действия по предотвращению паники', prev: '/page42', next: '/quiz5', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme5' },
    component: () => import(/* webpackChunkName: "page43" */ '../views/Page43.vue')
  },
  {
    path: '/page44',
    name: 'Page44',
    meta: { page: 'page44', title: 'Оказание первой помощи пострадавшим при пожаре', prev: '/theme6', next: '/page45', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme6' },
    component: () => import(/* webpackChunkName: "page44" */ '../views/Page44.vue')
  },
  {
    path: '/page45',
    name: 'Page45',
    meta: { page: 'page45', title: 'Первая помощь при ожогах', prev: '/page44', next: '/page46', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme6' },
    component: () => import(/* webpackChunkName: "page45" */ '../views/Page45.vue')
  },
  {
    path: '/page46',
    name: 'Page46',
    meta: { page: 'page46', title: 'Первая помощь при отравлении продуктами сгорания', prev: '/page45', next: '/page47', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme6' },
    component: () => import(/* webpackChunkName: "page46" */ '../views/Page46.vue')
  },
  {
    path: '/page47',
    name: 'Page47',
    meta: { page: 'page47', title: 'Термоингаляционное поражение дыхательных путей', prev: '/page46', next: '/page48', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme6' },
    component: () => import(/* webpackChunkName: "page47" */ '../views/Page47.vue')
  },
  {
    path: '/page48',
    name: 'Page48',
    meta: { page: 'page48', title: 'Первая помощь при травматическом шоке', prev: '/page47', next: '/page49', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme6' },
    component: () => import(/* webpackChunkName: "page48" */ '../views/Page48.vue')
  },
  {
    path: '/page49',
    name: 'Page49',
    meta: { page: 'page49', title: 'Первая помощь при ранениях', prev: '/page48', next: '/page50', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme6' },
    component: () => import(/* webpackChunkName: "page49" */ '../views/Page49.vue')
  },
  {
    path: '/page50',
    name: 'Page50',
    meta: { page: 'page50', title: 'Первая помощь при кровотечении', prev: '/page49', next: '/page51', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme6' },
    component: () => import(/* webpackChunkName: "page50" */ '../views/Page50.vue')
  },
  {
    path: '/page51',
    name: 'Page51',
    meta: { page: 'page51', title: 'Первая помощь при переломах костей', prev: '/page50', next: '/quiz6', progress: true, arrows: true, menu: true, info: true, auth: true, nav: 'theme6' },
    component: () => import(/* webpackChunkName: "page50" */ '../views/Page51.vue')
  },
  {
    path: '/end',
    name: 'End',
    meta: { page: 'end' },
    component: () => import(/* webpackChunkName: "end" */ '../views/End.vue')
  },
  {
    path: '/quiz1',
    name: 'Quiz1',
    meta: { page: 'quiz1', title: 'Тест по теме: "Основные нормативные документы, регламентирующие требования пожарной безопасности"', arrows: false, auth: true },
    component: () => import(/* webpackChunkName: "quiz" */ '../views/Quiz1.vue')
  },
  {
    path: '/quiz2',
    name: 'Quiz2',
    meta: { page: 'quiz2', title: 'Тест по теме: "Организационные мероприятия по обеспечению ПБ в зданиях и помещениях с массовым скоплением людей"', arrows: false, auth: true },
    component: () => import(/* webpackChunkName: "quiz2" */ '../views/Quiz2.vue')
  },
  {
    path: '/quiz3',
    name: 'Quiz3',
    meta: { page: 'quiz3', title: 'Тест по теме: "Меры ПБ в зданиях и помещениях с массовым скоплением людей"', arrows: false, auth: true },
    component: () => import(/* webpackChunkName: "quiz3" */ '../views/Quiz3.vue')
  },
  {
    path: '/quiz4',
    name: 'Quiz4',
    meta: { page: 'quiz4', title: 'Тест по теме: "Первичные средства пожаротушения, автоматические установки пожарной сигнализации и пожаротушения"', arrows: false, auth: true },
    component: () => import(/* webpackChunkName: "quiz4" */ '../views/Quiz4.vue')
  },
  {
    path: '/quiz5',
    name: 'Quiz5',
    meta: { page: 'quiz5', title: 'Тест по теме: "Действия сотрудников и руководителя при возникновении пожара, вызов пожарной охраны"', arrows: false, auth: true },
    component: () => import(/* webpackChunkName: "quiz4" */ '../views/Quiz5.vue')
  },
  {
    path: '/quiz6',
    name: 'Quiz6',
    meta: { page: 'quiz6', title: 'Тест по теме: "Оказание первой помощи пострадавшим при пожаре"', arrows: false, auth: true },
    component: () => import(/* webpackChunkName: "quiz4" */ '../views/Quiz6.vue')
  },
  {
    path: '/theme1',
    name: 'Theme1',
    meta: { page: 'theme1', title: 'Основные нормативные документы, регламентирующие требования пожарной безопасности', menu: true, info: true, auth: true, nav: 'theme1' },
    component: () => import(/* webpackChunkName: "theme1" */ '../views/Theme1.vue')
  },
  {
    path: '/theme2',
    name: 'Theme2',
    meta: { page: 'theme2', title: 'Организационные мероприятия по обеспечению ПБ в зданиях и помещениях с массовым скоплением людей', menu: true, info: true, auth: true, nav: 'theme2' },
    component: () => import(/* webpackChunkName: "theme2" */ '../views/Theme2.vue')
  },
  {
    path: '/theme3',
    name: 'Theme3',
    meta: { page: 'theme3', title: 'Меры ПБ в зданиях и помещениях с массовым скоплением людей', menu: true, info: true, auth: true, nav: 'theme3' },
    component: () => import(/* webpackChunkName: "theme3" */ '../views/Theme3.vue')
  },
  {
    path: '/theme4',
    name: 'Theme4',
    meta: { page: 'theme4', title: 'Первичные средства пожаротушения, автоматические установки пожарной сигнализации и пожаротушения', menu: true, info: true, auth: true, nav: 'theme4' },
    component: () => import(/* webpackChunkName: "theme3" */ '../views/Theme4.vue')
  },
  {
    path: '/theme5',
    name: 'Theme5',
    meta: { page: 'theme5', title: 'Действия сотрудников и руководителя при возникновении пожара, вызов пожарной охраны', menu: true, info: true, auth: true, nav: 'theme5' },
    component: () => import(/* webpackChunkName: "theme3" */ '../views/Theme5.vue')
  },
  {
    path: '/theme6',
    name: 'Theme6',
    meta: { page: 'theme6', title: 'Оказание первой помощи пострадавшим при пожаре', menu: true, info: true, auth: true, nav: 'theme6' },
    component: () => import(/* webpackChunkName: "theme3" */ '../views/Theme6.vue')
  },
  {
    path: '/workshop1',
    name: 'Workshop1',
    meta: { page: 'workshop1', title: 'Практическое задание', menu: false, info: false, auth: true, nav: 'theme1' },
    component: () => import(/* webpackChunkName: "theme3" */ '../views/Workshop1.vue')
  },
  {
    path: '/workshop2-1',
    name: 'Workshop2-1',
    meta: { page: 'workshop2-1', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "Workshop2" */ '../views/Workshop2-1.vue')
  },
  {
    path: '/workshop2-2',
    name: 'Workshop2-2',
    meta: { page: 'workshop2-2', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "Workshop2" */ '../views/Workshop2-2.vue')
  },
  {
    path: '/workshop2-3',
    name: 'Workshop2-3',
    meta: { page: 'workshop2-3', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "Workshop2" */ '../views/Workshop2-3.vue')
  },
  {
    path: '/workshop2-4',
    name: 'Workshop2-4',
    meta: { page: 'workshop2-4', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "Workshop2" */ '../views/Workshop2-4.vue')
  },
  {
    path: '/workshop3',
    name: 'Workshop3',
    meta: { page: 'workshop3', title: 'Эвакуация', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "theme3" */ '../views/Workshop3.vue')
  },
  {
    path: '/workshop4',
    name: 'Workshop4',
    meta: { page: 'workshop4', title: 'Виртуально погасить возорание, используя подходящий для этого тип огнетушителя', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "theme3" */ '../views/Workshop4.vue')
  },
  {
    path: '/workshop6-1',
    name: 'Workshop6-1',
    meta: { page: 'workshop6-1', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "workshop6-1" */ '../views/Workshop6-1.vue')
  },
  {
    path: '/workshop6-2',
    name: 'Workshop6-2',
    meta: { page: 'workshop6-2', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "workshop6-2" */ '../views/Workshop6-2.vue')
  },
  {
    path: '/workshop6-3',
    name: 'Workshop6-3',
    meta: { page: 'workshop6-3', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "workshop6-3" */ '../views/Workshop6-3.vue')
  },
  {
    path: '/workshop6-4',
    name: 'Workshop6-4',
    meta: { page: 'workshop6-4', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "workshop6-4" */ '../views/Workshop6-4.vue')
  },
  {
    path: '/workshop6-5',
    name: 'Workshop6-5',
    meta: { page: 'workshop6-5', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "workshop6-5" */ '../views/Workshop6-5.vue')
  },
  {
    path: '/workshop6-6',
    name: 'Workshop6-6',
    meta: { page: 'workshop6-6', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "workshop6-6" */ '../views/Workshop6-6.vue')
  },
  {
    path: '/taskworkshop4',
    name: 'Taskworkshop4',
    meta: { page: 'taskworkshop4', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "theme3" */ '../views/TaskWorkshop4.vue')
  },
  {
    path: '/taskworkshop3',
    name: 'Taskworkshop3',
    meta: { page: 'taskworkshop3', title: 'Практическое задание', menu: false, info: true, auth: true },
    component: () => import(/* webpackChunkName: "theme3" */ '../views/TaskWorkshop3.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const auth = localStorage.auth
  const reguaredAuthPage = to.matched.some(r => r.meta.auth)
  // const pageCheck = to.matched.some(r => r.meta.nav)
  // const token = this.$route.params.id
  document.title = 'ПТМ'

  if (!auth && reguaredAuthPage) {
    next('/auth')
  } else {
    next()
  }
  // if (!auth && reguaredAuthPage) {
  //   next('/auth')
  // } else if (auth && pageCheck) {
  //   next('/')
  // } else {
  //   next()
  // }
})

export default router
