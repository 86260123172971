export default {
  actions: {},
  mutations: {},
  state: {
    questions: [
      // {
      //   id: 1,
      //   text: 'Какой аварийный режим возникает при неплотном контакте в местах соединения и оконцевания проводов?',
      //   type: 'radio',
      //   answer: [
      //     { text: 'короткое замыкание ', correct: true },
      //     { text: 'перегрузка' },
      //     { text: 'переходное сопротивление' },
      //     { text: 'электродуга' }
      //   ]
      // },
      {
        id: 1,
        text: 'Какой аварийный режим чаще всего возникает при нарушении изоляции электрооборудования?',
        type: 'radio',
        answer: [
          { text: 'короткое замыкание ', correct: true },
          { text: 'перегрузка' },
          { text: 'переходное сопротивление' },
          { text: 'электродуга' }
        ]
      },
      {
        id: 2,
        text: 'Горючие жидкости имеют температуру воспламенения',
        type: 'radio',
        answer: [
          { text: 'от -18°С до 23°С' },
          { text: 'от 23°С до 60°С' },
          { text: 'от 60°С до 120°С', correct: true },
          { text: 'от -18°С (и ниже) до 61°С' }
        ]
      },
      {
        id: 3,
        text: 'Можно ли использовать электронагревательные приборы при отсутствии или неисправности терморегуляторов, предусмотренных конструкцией?',
        type: 'radio',
        answer: [
          { text: 'можно, если на это есть разрешение ответственного за электрохозяйство (подразделения)' },
          { text: 'правилами пожарной безопасности это запрещено', correct: true },
          { text: 'можно, если это не угрожает жизни и здоровью людей' },
          { text: 'можно, если это обусловлено острой необходимостью' }
        ]
      },
      {
        id: 4,
        type: 'radio',
        text: 'Какое из перечисленных действий соответствует требованиям правил ПБ при эксплуатации действующих электроустановок?',
        answer: [
          { text: 'эксплуатировать приемники электрической энергии (электроприемники) в условиях, не соответствующих требованиям инструкций организаций-изготовителей' },
          { text: 'эксплуатировать установку пожаротушения и систему пожарной сигнализации в течение рабочего дня и по окончании рабочего времени', correct: true },
          { text: 'эксплуатировать нестандартные (самодельные) электронагревательные приборы, использовать некалиброванные плавкие вставки или другие самодельные аппараты защиты от перегрузки и короткого замыкания' },
          { text: 'эксплуатировать электропровода и кабели с поврежденной или потерявшей защитные свойства изоляцией' }
        ]
      },
      {
        id: 5,
        type: 'radio',
        text: 'Что соответствует требованиям, предъявляемым к объемным самосветящимся знакам пожарной безопасности, используемым на путях эвакуации?',
        answer: [
          { text: 'должны постоянно находиться в исправном состоянии и отключаться по окончании рабочего времени' },
          { text: 'должны постоянно находиться в исправном состоянии и автоматически включаться в темное время суток и при отключении рабочего освещения' },
          { text: 'должны постоянно находиться в исправном состоянии и автоматически включаться при пожарной сигнализации' },
          { text: 'должны постоянно находиться в исправном и включенном состоянии', correct: true }
        ]
      },
      {
        id: 6,
        type: 'radio',
        text: 'В начале рабочего дня перед тем, как включить принтер, Вы услышали треск и искрение из розетки. Какие меры необходимо предпринять?',
        answer: [
          { text: 'попробую разобраться в чем дело и починить' },
          { text: 'включу принтер и начну работу' },
          { text: 'оповещу руководителя о замеченной неисправности и напишу записку на принтере, чтобы никто его не включал ', correct: true },
          { text: 'никакие' }
        ]
      },
      {
        id: 7,
        type: 'radio',
        text: 'Какой единый номер установлен для приема сообщений о пожарах и чрезвычайных ситуациях?',
        answer: [
          { text: '109' },
          { text: '101', correct: true },
          { text: '102' },
          { text: '103' }
        ]
      },
      {
        id: 8,
        type: 'radio',
        text: 'При каком количестве людей, единовременно находящихся на этажах зданий и сооружений, вывешиваются планы эвакуации людей?',
        answer: [
          { text: 'более 5 человек' },
          { text: 'более 10 человек', correct: true },
          { text: 'более 50 человек' },
          { text: 'более 100 человек' }
        ]
      },
      {
        id: 9,
        type: 'radio',
        text: 'Какие лестничные клетки в зависимости от способа защиты от задымления при пожаре не относятся к незадымляемым лестничным клеткам?',
        answer: [
          { text: 'лестничные клетки с входом на лестничную клетку с этажа через незадымляемую наружную воздушную зону по открытым переходам' },
          { text: 'лестничные клетки с подпором воздуха на лестничную клетку при пожаре' },
          { text: 'лестничные клетки с естественным освещением через открытые проемы в наружных стенах на каждом этаже', correct: true },
          { text: 'лестничные клетки с входом на них на каждом этаже через тамбур-шлюз, в котором постоянно или во время пожара обеспечивается подбор воздуха' }
        ]
      },
      {
        id: 10,
        type: 'radio',
        text: 'Что не соответствует требованиям правил ПБ к эвакуационным путям и выходам?',
        answer: [
          { text: 'остекление дверей и фрамуг эвакуационных выходов должно выполняться армированным стеклом' },
          { text: 'двери эвакуационных выходов должны открываться свободно и по направлению выхода из здания' },
          { text: 'запоры на дверях эвакуационных выходов должны обеспечивать людям, находящимся внутри здания (сооружения), возможность свободного открывания запоров изнутри без ключа' },
          { text: 'в тамбурах эвакуационных выходов разрешается хранить только инвентрать для уборки помещений', correct: true }

        ]
      }
    ]
  },
  getters: {
    allQuestionsQuiz3 (state) {
      return state.questions
    }
  }
}
