export default {
  actions: {},
  mutations: {},
  state: {
    questions: [
      {
        id: 1,
        text: 'Какие сведения необходимо сообщать во время звонка в пожарную охрану при обнаружении пожара или признаков горения?',
        type: 'radio',
        answer: [
          { text: 'адрес объекта, место возгорания, количество пострадавших' },
          { text: 'адрес объекта, место возгорания, данные позвонившего' },
          { text: 'адрес, по которому случилось возгорание, количество пострадавших' },
          { text: 'адрес объекта, место возгорания, количество пострадавших, данные позвонившего', correct: true }
        ]
      },
      {
        id: 2,
        text: 'Определите вид панической атаки по описанию поведения: <ul><li>хаотическое метание;</li><li>дрожание рук;</li><li>ускоренная речь;</li><li>непоследовательные высказывания.</li></ul>',
        type: 'radio',
        answer: [
          { text: 'Фуга (бег) ', correct: true },
          { text: 'Ступор (оцепенение)' }
        ]
      },
      {
        id: 3,
        text: 'Главное правило при пожаре:',
        type: 'radio',
        answer: [
          { text: 'быстро и без паники покинуть горящее здание, оповещая остальных и помогая отстающим' },
          { text: 'прекратить все работы в здании, кроме работ по ликвидации пожара' },
          { text: 'вызвать пожарную охрану даже если пожар уже потушен своими силами' },
          { text: 'как можно быстрее сообщить о пожаре' },
          { text: 'не паниковать', correct: true }
        ]
      },
      {
        id: 4,
        type: 'radio',
        text: 'Какие сведения руководство организации должно сообщить прибывшему на место руководителю тушения пожара?',
        answer: [
          { text: 'конструктивные и технологические особенности объекта, сведения о прилегающих строениях, количество и пожароопасные свойства хранимых и применяемых веществ и материалов', correct: true },
          { text: 'конструктивные и технологические особенности объекта, количество и пожароопасные свойства хранимых и применяемых веществ и материалов' },
          { text: 'сведения о прилегающих строениях, количество и пожароопасные свойства хранимых и применяемых веществ и материалов, причин возгорания' },
          { text: 'конструктивные и технологические особенности объекта, причины возгорания, масштабы пожара' }
        ]
      },
      {
        id: 5,
        type: 'radio',
        text: 'Что из перечисленного не должен делать гражданин при обнаружении пожара или признаков горения (задымления, запах гари, повышение температуры и т.п.)?',
        answer: [
          { text: 'принять по возможности меры по эвакуации людей и сохранности материальных ценностей' },
          { text: 'вызвать скорую помощь', correct: true },
          { text: 'сообщить об этом по телефону в пожарную охрану' },
          { text: 'Принять по возможности меры по тушению пожара' }
        ]
      }
    ]
  },
  getters: {
    allQuestionsQuiz5 (state) {
      return state.questions
    }
  }
}
