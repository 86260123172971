export default {
  actions: {},
  mutations: {},
  state: {
    items: [
      {
        action: 'mdi-numeric-1-box-outline',
        items: [
          { title: 'Основные нормативные документы, регламентирующие требования пожарной безопасности', to: '/page1' },
          { title: 'Инструкция о мерах пожарной безопасности', to: '/page2' },
          { title: 'Система обеспечения пожарной безопасности', to: '/page3' },
          { title: 'Права и обязанности граждан и руководителей', to: '/page4' },
          { title: 'Ответственность за обеспечение ПБ', to: '/page5' },
          { title: 'Политика пао «Газпром» в области охраны труда, промышленной и пожарной безопасности, безопасности дорожного движения', to: '/page5-1' },
          { title: 'Тест по теме', to: '/quiz1' },
          { title: 'Практическое задание', to: '/workshop1' }
        ],
        title: 'Законодательная база в области пожарной безопасности'
      },
      {
        action: 'mdi-numeric-2-box-outline',
        items: [
          { title: 'Основные причины пожаров в городской местности', to: '/page6' },
          { title: 'Статистика пожаров в общественных зданиях', to: '/page7' },
          { title: 'Факторы, представляющие опасность для при пожарах', to: '/page8' },
          { title: 'Организационные мероприятия по установлению противопожарного режима', to: '/page9' },
          { title: 'Порядок обучения служащих мерам ПБ на рабочих местах', to: '/page10' },
          { title: 'Создание пожарно-технических комиссий и добровольных пожарных дружин', to: '/page11' },
          { title: 'Тест по теме', to: '/quiz2' }
        ],
        title: 'Организационные мероприятия по обеспечению ПБ<br> в зданиях и помещениях с массовым пребыванием людей'
      },
      {
        action: 'mdi-numeric-3-box-outline',
        items: [
          { title: 'Меры ПБ при эксплуатации электрических сетей, электрооборудования <br> и электронагревательных приборов', to: '/page12' },
          { title: 'Общие правила при эксплуатации электроустановок, электросетей,<br> электрооборудования', to: '/page13' },
          { title: 'Требования ПБ при работе с электроприборами, офисным <br>оборудованием и оргтехникой', to: '/page14' },
          { title: 'Запрещенные действия', to: '/page15' },
          { title: 'Аварийные режимы работы электроустановок', to: '/page16' },
          { title: 'Воспламеняющиеся жидкости', to: '/page17' },
          { title: 'Пожаровзрывоопасность', to: '/page18' },
          { title: 'Хранение, обращение, транспортирование ЛВЖ, ГЖ, ГГ', to: '/page19' },
          { title: 'Разработка плана эвакуации людей при пожаре, содержание путей эвакуации', to: '/page20' },
          { title: 'Эвакуационные выходы', to: '/page21' },
          { title: 'Запрещенные дейстия при эксплуатации эвакуационныз путей,<br> эвакуационных и аварийных выходов', to: '/page22' },
          { title: 'Пользование лифтами во время пожара', to: '/page23' },
          { title: 'Особенности распростронения огня в зданиях повышенной этажности', to: '/page24' },
          { title: 'Повышенная опасность продуктов горения', to: '/page25' },
          { title: 'Незадымляемые лестничные клетки', to: '/page26' },
          { title: 'Порядок хранения печатной продукции и документов', to: '/page27' },
          { title: 'Тест по теме', to: '/quiz3' },
          { title: 'Практическое задание', to: '/taskworkshop3' }
        ],
        title: 'Меры ПБ в зданиях и помещениях с массовым скоплением людей'
      },
      {
        action: 'mdi-numeric-4-box-outline',
        items: [
          { title: 'Первичные средства пожаротушения', to: '/page28' },
          { title: 'Порошковый огнетушитель', to: '/page29' },
          { title: 'Углекислотный огнетушитель', to: '/page30' },
          { title: 'Аэрозольный огнетушитель', to: '/page31' },
          { title: 'Воздушно-пенный огнетушитель', to: '/page32' },
          { title: 'Автоматическая установка пожаротушения (АУП)', to: '/page33' },
          { title: 'Автоматическая установка пожарной сигнализации (АУПС)', to: '/page34' },
          { title: 'Нормы обеспечения учреждений средствами пожаротушения', to: '/page35' },
          { title: 'Тест по теме', to: '/quiz4' },
          { title: 'Практическое задание "Определить порядок действий при работе с огнетушителем"', to: '/workshop2-1' },
          { title: 'Практическое задание "Потоушить виртуальное возгорание"', to: '/taskworkshop4' }
        ],
        title: 'Первичные средства пожаротушения, автоматические<br> установки пожарной сигнализации и пожаротушения'
      },
      {
        action: 'mdi-numeric-5-box-outline',
        items: [
          { title: 'Действия сотрудников при возникновении пожара', to: '/page36' },
          { title: 'Запрещенные действия при тушении пожара', to: '/page37' },
          { title: 'Обязанности руководителя при возникновении пожара', to: '/page38' },
          { title: 'Вызов, встреча и сопровождение пожарных команд к месту пожара', to: '/page39' },
          { title: 'Порядок проведения эвакуации', to: '/page40' },
          { title: 'Правила проведения эвакуации', to: '/page41' },
          { title: 'Действия в случае значительного задымления', to: '/page42' },
          { title: 'Действия по предотвращению паники', to: '/page43' },
          { title: 'Тест по теме', to: '/quiz5' }
        ],
        title: 'Действия сотрудников и руководителя при возникновении пожара, вызов пожарной охраны'
      },
      {
        action: 'mdi-numeric-6-box-outline',
        items: [
          { title: 'Оказание первой помощи пострадавшим при пожаре', to: '/page44' },
          { title: 'Первая помощь при ожогах', to: '/page45' },
          { title: 'Первая помощь при отравлении продуктами сгорания', to: '/page46' },
          { title: 'Термоингаляционное поражение дыхательных путей', to: '/page47' },
          { title: 'Первая помощь при травматическом шоке', to: '/page48' },
          { title: 'Первая помощь при ранениях', to: '/page49' },
          { title: 'Первая помощь при кровотичении', to: '/page50' },
          { title: 'Первая помощь при переломах костей', to: '/page51' },
          { title: 'Тест по теме', to: '/quiz6' },
          { title: 'Практическое задание', to: '/workshop6-1' }
        ],
        title: 'Оказание первой помощи пострадавшим при пожаре'
      }
    ]
  },
  getters: {
    allMenu (state) {
      return state.items
    }
  }
}
