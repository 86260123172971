export default {
  actions: {},
  mutations: {},
  state: {
    questions: [
      {
        id: 1,
        text: 'Что не учитывается при определении видов и количества первичных средств пожаротушения?',
        type: 'radio',
        answer: [
          { text: 'площадь производственных помещений, открытых площадок и установок' },
          { text: 'физико-химические и пожароопасные свойства горючих веществ' },
          { text: 'отношение горючих веществ к огнетушащим веществам' },
          { text: 'количество работающих ', correct: true }
        ]
      },
      {
        id: 2,
        text: 'В каком документе указаны нормы обеспечения объектов защиты огнетушителями?',
        type: 'radio',
        answer: [
          { text: 'Федеральный закон от 21.12.1994 № 69-ФЗ «О пожарной безопасности»', correct: true },
          { text: 'Федеральный закон от 22.07.2008 № 123-ФЗ «Технический регламент о требованиях пожарной безопасности»' },
          { text: 'Постановление Правительства РФ от 25.04.2012 № 390 «О противопожарном режиме»' },
          { text: 'нет правильного ответа' }
        ]
      },
      {
        id: 3,
        text: 'В каком состоянии относительно друг друга должны находиться пожарный кран, ствол и рукав?',
        type: 'radio',
        answer: [
          { text: 'пожарный рукав и ствол присоединены друг к другу, кран не присоединен' },
          { text: 'пожарный рукав должен быть присоединен к крану, ствол – в разомкнутом состоянии' },
          { text: 'пожарный рукав должен быть присоединен к крану и стволу', correct: true },
          { text: 'пожарный кран, рукав и ствол должны находиться в разомкнутом состоянии' }
        ]
      },
      {
        id: 4,
        type: 'radio',
        text: 'Пожарный кран ЗАПРЕЩЕНО использовать для тушения:',
        answer: [
          { text: 'электроустановок под напряжением', correct: true },
          { text: 'в помещениях, где применяются или хранятся ЛВЖ или ГЖ' },
          { text: 'горящей одежды на людях' }
        ]
      },
      {
        id: 5,
        type: 'radio',
        text: 'Укажите периодичность перекатки рукавов на новую скатку',
        answer: [
          { text: 'после использования' },
          { text: '1 раз в год', correct: true },
          { text: '2 раза в год (весной и осенью)' }

        ]
      },
      {
        id: 6,
        type: 'radio',
        text: 'Какой вид огнетушителя ЗАПРЕЩЕНО применять при возгорании электрооборудования под напряжением?',
        answer: [
          { text: 'порошковый' },
          { text: 'углекислотный' },
          { text: 'воздушно-пенный', correct: true },
          { text: 'аэрозольный' }
        ]
      },
      {
        id: 7,
        type: 'radio',
        text: 'Какой вид огнетушителя является самым универсальным и способен работать при температурах воздуха от -40 до +500°С?',
        answer: [
          { text: 'порошковый' },
          { text: 'углекислотный ', correct: true },
          { text: 'воздушно-пенный' },
          { text: 'аэрозольный' }
        ]
      },
      {
        id: 8,
        type: 'radio',
        text: 'Какой вид огнетушителя лучше применять при возгорании компьютеров и оргтехники, так как их использование не сопровождается загрязнением?',
        answer: [
          { text: 'порошковый' },
          { text: 'углекислотный' },
          { text: 'воздушно-пенный', correct: true },
          { text: 'аэрозольный' }
        ]
      },
      {
        id: 9,
        type: 'radio',
        text: 'Время работы пожарного крана',
        answer: [
          { text: '1 час', correct: true },
          { text: '2 часа' },
          { text: '3 часа' },
          { text: 'нет правильного ответа' }
        ]
      },
      {
        id: 10,
        type: 'radio',
        text: 'Как часто необходимо проверять огнетушители?',
        answer: [
          { text: 'каждый год' },
          { text: 'раз в 2 года' },
          { text: 'перед проверкой' },
          { text: '2 раза в год', correct: true }

        ]
      }
    ]
  },
  getters: {
    allQuestionsQuiz4 (state) {
      return state.questions
    }
  }
}
