export default {
  actions: {},
  mutations: {},
  state: {
    questions: [
      {
        id: 1,
        text: 'Какой инструктаж должен пройти новый сотрудник до начала самостоятельной работы?',
        type: 'radio',
        answer: [
          { text: 'вводный' },
          { text: 'вводный и целевой' },
          { text: 'первичный и целевой' },
          { text: 'вводный и первичный', correct: true }
        ]
      },
      {
        id: 2,
        text: 'С кем НЕ проводится вводный противопожарный инструктаж?',
        type: 'radio',
        answer: [
          { text: 'с сезонными работниками' },
          { text: 'с обучающимися, прибывшими на производственное обучение или практику' },
          { text: 'с командированными в организацию работниками' },
          { text: 'с прибывшими на экскурсию', correct: true }
        ]
      },
      {
        id: 3,
        text: 'Периодичность обучения ПТМ для организаций, не связанных с взрывопожароопасным производством:',
        type: 'radio',
        answer: [
          { text: 'сразу при приеме на работу и далее не реже 1 раза в год' },
          { text: 'сразу при приеме на работу и далее не реже 2 раз в год' },
          { text: 'в течении 1 месяца после приема на работу и далее не реже 1 раза в год' },
          { text: 'в течении 1 месяца после приема на работу и далее не реже 1 раза в 3 года', correct: true }
        ]
      },
      {
        id: 4,
        type: 'radio',
        text: 'Кто проходит обучение ПТМ непосредственно в организации?',
        answer: [
          { text: 'работники бухгалтерии' },
          { text: 'работники, ответственные за обеспечение ПБ в подразделениях', correct: true },
          { text: 'работники планово-экономической службы' },
          { text: 'работники службы управления персоналом' }
        ]
      },
      {
        id: 5,
        type: 'radio',
        text: 'В каком случае целевой противопожарный инструктаж не проводится?',
        answer: [
          { text: 'при организации массовых мероприятий с обучающимися' },
          { text: 'при ликвидации последствий аварий, стихийных бедствий и катастроф' },
          { text: 'при выполнении работ во вторую смену', correct: true },
          { text: 'при подготовке в организации мероприятий с массовым пребыванием людей (собрания, конференции, совещания и т.п.) с числом участников более 50 человек' }
        ]
      },
      {
        id: 6,
        type: 'radio',
        text: 'Что не относится к опасным факторам пожара, воздействующим на людей и имущество?',
        answer: [
          { text: 'повышенная температура окружающей среды' },
          { text: 'повышенная концентрация токсичных продуктов горения и термического разложения' },
          { text: 'повышенный уровень электромагнитного поля', correct: true },
          { text: 'пониженная концентрация кислорода' }
        ]
      },
      {
        id: 7,
        type: 'radio',
        text: 'В каком случае внеплановый противопожарный инструктаж не проводится?',
        answer: [
          { text: 'при приеме в отдел (рабочую группу) нового работника', correct: true },
          { text: 'при введении в действие новых или изменении ранее разработанных правил, норм и инструкций по ПБ, инных документов, содержащих требования ПБ' },
          { text: 'при нарушении работниками организации требований ПБ, которые не могли привести или привели к пожару' },
          { text: 'при изменении технологического процесса производства, замене или модернизации оборудования, инструментов, исходного сырья, материалов, изменении факторов, влияющих на противопожарное состояние объекта' }
        ]
      },
      {
        id: 8,
        type: 'radio',
        text: 'Должен ли руководитель организации обеспечить обучение своих работников мерам пожарной безопасности?',
        answer: [
          { text: 'руководитель организации обязан обучать своих работников, если это предусмотрено коллективным договором' },
          { text: 'руководитель организации обязан обучать своих работников', correct: true },
          { text: 'в обязанности руководителя организации это не входит' },
          { text: 'руководитель организации делает это по своему усмотрению' }
        ]
      },
      {
        id: 9,
        type: 'radio',
        text: 'Периодичность проведения повторного противопожарного инструктажа:',
        answer: [
          { text: 'со всеми работниками не реже 2 раз в год, работникам пожароопасного производства 1 раз в 3 месяца' },
          { text: 'со всеми работниками не реже 1 раза в год, работникам пожароопасного производства 1 раз в 6 месяца', correct: true },
          { text: 'со всеми работниками не реже 1 раза в 2 года, работникам пожароопасного производства 1 раз в год' },
          { text: 'нет правильного ответа' }
        ]
      },
      {
        id: 10,
        type: 'radio',
        text: 'Что из перечисленного не входит в цели проведения противопожарного инструктажа?',
        answer: [
          { text: 'обучение работников необходимым действиям в случае возникновения пожара' },
          { text: 'ознакомление работников с действиями пожарных во время тушения пожара', correct: true },
          { text: 'доведение до работников организаций освновных требований ПБ' },
          { text: 'изучение пожарной опасности технологических процессов производств и оборудования, средств противопожарной защиты' }

        ]
      }
    ]
  },
  getters: {
    allQuestionsQuiz2 (state) {
      return state.questions
    }
  }
}
