export default {
  actions: {},
  mutations: {},
  state: {
    questions: [
      {
        id: 1,
        text: 'С какой периодичностью руководитель организации обязан обеспечивать проверку средств индивидуальной защиты органов дыхания и зрения человека от опасных факторов пожара на предмет отсутствия механических повреждений и их целостности с отражением информации в журнале эксплуатации систем противопожарной защиты?',
        type: 'radio',
        answer: [
          { text: '1 раз в год', correct: true },
          { text: '1 раз в 3 года' },
          { text: '1 раз в 5 лет' },
          { text: 'нет правильного ответа' }
        ]
      },
      {
        id: 2,
        text: 'Что относится к обязанностям граждан в области пожарной безопасности?',
        type: 'radio',
        answer: [
          { text: 'соблюдать требования пожарной безопасности' },
          { text: 'иметь в помещениях и строениях, находящихся в их собственности (пользовании), первичные средства тушения пожаров и противопожарный инвентарь' },
          { text: 'до прибытия пожарной охраны принимать посильные меры по спасению людей, имущества и тушению пожаров' },
          { text: 'оказывать содействие пожарной охране при тушении пожаров' },
          { text: 'выполнять предписания, постановления и иные законные требования должностных лиц государственного пожарного надзора' },
          { text: 'предоставлять в порядке, установленном законодательством Российской Федерации, возможность должностным лицам государственного пожарного надзора проводить обследования и проверки принадлежащих им производственных, хозяйственных и иных помещений и строений (за исключением жилых помещений), территорий, земельных участков в целях контроля за соблюдением требований пожарной безопасности и пресечения их нарушений' },
          { text: 'все вышеперечисленное', correct: true }
        ]
      },
      {
        id: 3,
        text: 'Кто несет ответственность за нарушение требований пожарной безопасности?',
        type: 'radio',
        answer: [
          { text: 'собственники имущества' },
          { text: 'руководители федеральных органов исполнительной власти' },
          { text: 'руководители органов местного самоуправления' },
          { text: 'лица, уполномоченные владеть, пользоваться или распоряжаться имуществом, в том числе руководители организаций' },
          { text: 'лица, в установленном порядке назначенные ответственными за обеспечение пожарной безопасности' },
          { text: 'должностные лица в пределах их компетенции' },
          { text: 'все вышеперечисленное', correct: true }
        ]
      },
      {
        id: 4,
        type: 'radio',
        text: 'Что НЕ относится к опасным факторам пожара, воздействующим на людей и имущество?',
        answer: [
          { text: 'пламя и искры' },
          { text: 'тепловой поток' },
          { text: 'повышенная температура окружающей среды' },
          { text: 'повышенная концентрация токсичных продуктов горения и термического разложения' },
          { text: 'пониженная концентрация кислорода' },
          { text: 'снижение видимости в дыму' },
          { text: 'снижение слышимости', correct: true },
          { text: 'нет правильного ответа' }
        ]
      },
      {
        id: 5,
        type: 'radio',
        text: 'Что из перечисленного не входит в основные функции системы обеспечения пожарной безопасности?',
        answer: [
          { text: 'осуществление государственного пожарного надзора и других контрольных функций по обеспечению пожарной безопасности' },
          { text: 'научно-техническое и информационное обеспечение в области ПБ' },
          { text: 'проведение противопожарной пропаганды  и обучение насиления мерам ПБ' },
          { text: 'строительство зданий, объектов в соответствии с требованиями ПБ', correct: true }
        ]
      },
      {
        id: 6,
        type: 'radio',
        text: 'Каким документом устанавливаются общие требования ПБ к зданиям и сооружениям?',
        answer: [
          { text: 'ФЗ № 116 "О промышленной безопасноти опасных производственных объектов"' },
          { text: 'ФЗ № 69 "О пожарной безопасности"' },
          { text: 'ФЗ № 123 "Технический регламент о требованиях пожарной безопасности"', correct: true },
          { text: 'Постановление Правительства РФ "О противопожарном режиме"' }
        ]
      },
      {
        id: 7,
        type: 'radio',
        text: 'Кто в организации несет ответственность за обеспечение пожарной безопасности?',
        answer: [
          { text: 'руководитель организации', correct: true },
          { text: 'руководители структурных подразделений организации, отделов' },
          { text: 'служба охраны труда' },
          { text: 'работники пожарного надзора по закрепленным за ними объектам' }
        ]
      },
      {
        id: 8,
        type: 'radio',
        text: 'Каким правом при обеспечении ПБ не обладают руководители организации?',
        answer: [
          { text: 'устанавливать меры социального и экономического стимулирования обеспечения пожарной безопасности в организации' },
          { text: 'получать информацию по вопросам пожарной безопасности, в том числе в установленном порядке от органов управления и подразделений пожарной охраны ' },
          { text: 'участвовать в производственных совещаниях территориального подразделения пожарной охраны', correct: true },
          { text: 'вносить в органы государственной власти и органы местного самоуправления предложения по обеспечению пожарной безопасности' }
        ]
      },
      {
        id: 9,
        type: 'radio',
        text: 'Какие вопросы не отражаются в инструкции о мерах пожарной безопасности',
        answer: [
          { text: 'обязанности и действия работников при пожаре' },
          { text: 'порядок содержания территорий, зданий и помещений, в том числе эвакуационных путей' },
          { text: 'места курения и применения открытого огня' },
          { text: 'режим работы, время отдыха, ответственность за нарушение трудовой дисциплины', correct: true }
        ]
      },
      {
        id: 10,
        type: 'radio',
        text: 'В каком из перечисленных документов определены правовые, экономические и социальные основы обеспечения пожарной безопасности?',
        answer: [
          { text: 'ФЗ № 69 "О пожарной безопасности"', correct: true },
          { text: 'ФЗ № 116 "О промышленной безопасноти опасных производственных объектов"' },
          { text: 'Постановление Правительства РФ "О противопожарном режиме"' },
          { text: 'ФЗ № 123 "Технический регламент о требованиях пожарной безопасности"' }

        ]
      }
    ]
  },
  getters: {
    allQuestionsQuiz1 (state) {
      return state.questions
    },
    questionsCount (state) {
      return state.questions.lenght
    }
  }
}
