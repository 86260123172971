<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-list
          three-line
          subheader
          class="mt-10"
        >
          <v-card-title class="mb-5">Нормативно-правовые документы</v-card-title>
          <!-- законы -->
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/FZ_69.pdf" download="Федеральный закон от 21.12.1994 № 69-ФЗ О пожарной безопасности.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          Федеральный закон от 21.12.1994 № 69-ФЗ "О пожарной безопасности"
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/FZ_100.pdf" download="Федеральный закон от 06.05.2011 № 100-ФЗ О добровольной пожарной охране.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          Федеральный закон от 06.05.2011 № 100-ФЗ "О добровольной пожарной охране"
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/FZ_123.pdf" download="Федеральный закон от 22.07.2008 № 123-ФЗ Технический регламент о требованиях пожарной безопасности.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          Федеральный закон от 22.07.2008 № 123-ФЗ "Технический регламент о требованиях пожарной безопасности"
          </a>
          <!-- постановления -->
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/PPR_Postanovlenie_N1479.pdf" download="Постановление Правительства РФ от 16.09.2020г. № 1479 Об утверждении Правил противопожарного режима.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          Постановление Правительства РФ от 16.09.2020г. № 1479 "Об утверждении Правил противопожарного режима"
          </a>
          <!-- приказы -->
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/Prikaz_MCHS.pdf" download="Приказ МЧС РФ от 12.12.2007 № 645 Об утверждении норм пожарной безопасности Обучение мерам пожарной безопасности работников организаций.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          Приказ МЧС РФ от 12.12.2007 № 645 "Об утверждении норм пожарной безопасности "Обучение мерам пожарной безопасности работников организаций"
          </a>
          <!-- гост -->
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/gost_12.1.004-91.pdf" download="ГОСТ 12.1.004-91 Система стандартов безопасности труда (ССБТ). Пожарная безопасность. Общие требования.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          ГОСТ 12.1.004-91 Система стандартов безопасности труда (ССБТ). Пожарная безопасность. Общие требования
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/gost_12.1.004-91.pdf" download="ГОСТ 12.1.044-2018 Система стандартов безопасности труда (ССБТ). Пожаровзрывоопасность веществ и материалов. Номенклатура показателей и методы их определения.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          ГОСТ 12.1.044-2018 Система стандартов безопасности труда (ССБТ). Пожаровзрывоопасность веществ и материалов. Номенклатура показателей и методы их определения
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/gost_12.1.044-2018.pdf" download="ГОСТ 12.4.026-2015 Система стандартов безопасности труда (ССБТ). Цвета сигнальные, знаки безопасности и разметка сигнальная. Назначение и правила применения. Общие технические требования и характеристики. Методы испытаний.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          ГОСТ 12.4.026-2015 Система стандартов безопасности труда (ССБТ). Цвета сигнальные, знаки безопасности и разметка сигнальная. Назначение и правила применения. Общие технические требования и характеристики. Методы испытаний
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/gost_19433-88.pdf" download="ГОСТ 19433-88 Грузы опасные. Классификация и маркировка.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          ГОСТ 19433-88 Грузы опасные. Классификация и маркировка
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/gost_27331-87.pdf" download="ГОСТ 27331-87. Пожарная техника. Классификация пожаров.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          ГОСТ 27331-87. Пожарная техника. Классификация пожаров.
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/gost_34442-2018" download="ГОСТ 34442-2018 (EN 81-73:2016) Лифты. Пожарная безопасность.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          ГОСТ 34442-2018 (EN 81-73:2016) Лифты. Пожарная безопасность
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/gost_r_51057-2001.pdf" download="ГОСТ Р 51057-2001 Техника пожарная. Огнетушители переносные. Общие технические требования. Методы испытаний.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          ГОСТ Р 51057-2001 Техника пожарная. Огнетушители переносные. Общие технические требования. Методы испытаний
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/gost_r_53278-2009.pdf" download="ГОСТ Р 53278-2009 Техника пожарная. Клапаны пожарные запорные. Общие технические требования. Методы испытаний.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          ГОСТ Р 53278-2009 Техника пожарная. Клапаны пожарные запорные. Общие технические требования. Методы испытаний
          </a>

          <!-- сп и снип -->
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/NPB_151-2000.pdf" download="НПБ 151-2000 Шкафы пожарные. Технические требования пожарной безопасности. Методы испытаний.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          НПБ 151-2000 Шкафы пожарные. Технические требования пожарной безопасности. Методы испытаний
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/SNiP_21-01-97.pdf" download="СНиП 21-01-97 Пожарная безопасность зданий и сооружений.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          СНиП 21-01-97 Пожарная безопасность зданий и сооружений
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/SP_4.13130.2013.pdf" download="Свод правил (СП 4.13130.2013). Системы противопожарной защиты. Ограничение распространения пожара на объектах защиты. Требования к объемно-планировочным и конструктивным решениям.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          Свод правил СП 4.13130.2013. Системы противопожарной защиты. Ограничение распространения пожара на объектах защиты. Требования к объемно-планировочным и конструктивным решениям</a>

          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/SP_01.13130.2020.pdf" download="Свод правил (СП 1.13130.2020). Системы противопожарной защиты. Эвакуационные пути и выходы.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          Свод правил СП 1.13130.2020. Системы противопожарной защиты. Эвакуационные пути и выходы
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/SP_01.13130.2020.pdf" download="Свод правил СП 484.1311500.2020. Системы противопожарной защиты. Системы пожарной сигнализации и автоматизация систем противопожарной защиты. Нормы и правила проектирования.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          Свод правил СП 484.1311500.2020. Системы противопожарной защиты. Системы пожарной сигнализации и автоматизация систем противопожарной защиты. Нормы и правила проектирования
          </a>
          <a class=" ptm-all-doc headline font-weight-light black--text pa-5 d-flex align-start mb-5" href="doc/ntd/sp_486_1311500_2020.pdf" download="СП 486.1311500.2020 Системы противопожарной защиты. Перечень зданий, сооружений, помещений и оборудования, подлежащих защите автоматическими установками пожаротушения и системами пожарной сигнализации. Нормы и правила проектирования.pdf">
          <v-icon color="red" class="mr-3" size="50">mdi-file-download-outline</v-icon>
          СП 486.1311500.2020 "Системы противопожарной защиты. Перечень зданий, сооружений, помещений и оборудования, подлежащих защите автоматическими установками пожаротушения и системами пожарной сигнализации. Нормы и правила проектирования"
          </a>

          <v-card-text class="d-flex justify-center subtitle-1">
            <v-icon
            color="red"
            class="mr-3"
            >
            mdi-cursor-default-click-outline</v-icon>
            Нажимайте на пункты списка для скачивания документов
          </v-card-text>
                    <div class="text-center">
            <!-- <v-pagination
              v-model="page"
              :length="2"
            >
            </v-pagination> -->
          </div>
        </v-list>
      </v-col>

    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    page: 1
  })
}
</script>
